<template lang="pug">
  v-container
    v-row(
      justify="center"
    )
      v-col(
        cols="12"
        md="8"
        lg="6"
      )
        h1.text-h4.mb-4 {{ verbage }} Product
        v-card(
          outlined
        )
          v-card-text
            FeathersVuexFormWrapper(
              :item="item"
            )
              template(v-slot="{ clone, save, reset, isDirty }")
                v-form(
                  @submit.prevent="save().then(handleSaveReponse)"
                  ref="form"
                  v-model="valid"
                )
                  v-row
                    v-col
                      v-text-field(
                        label="Part #"
                        filled
                        v-model="clone.name"
                        :rules="[rules.required]"
                      )

                  v-row
                    v-col
                      v-textarea(
                        label="Description"
                        filled
                        v-model="clone.description"
                        :rules="[rules.required]"
                      )

                  v-row
                    v-col
                      v-text-field(
                        label="Cost Price"
                        prefix="$"
                        filled
                        type="number"
                        v-model.number="clone.costPrice"
                        :rules="[rules.required]"
                      )
                    v-col
                      v-text-field(
                        label="List Price"
                        prefix="$"
                        filled
                        type="number"
                        v-model.number="clone.listPrice"
                        :rules="[rules.required]"
                      )

                  v-row
                    v-col
                      v-btn.mb-5(
                        color="secondary"
                        type="submit"
                        block
                        height="50px"
                        :disabled="!valid || !isDirty"
                      ) {{ verbage }} Product
                      v-btn(
                        block
                        @click="cancel"
                      ) Cancel
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex'
import validations from '@/mixins/validationRulesMixin'

export default {
  name: 'EditProduct',
  components: {
    FeathersVuexFormWrapper
  },
  mixins: [
    validations
  ],
  computed: {
    id () {
      return this.$route.params.productId
    },
    user () {
      return this.$store.getters['auth/user']
    },
    shopId () {
      return this.$route.query.shopId
    },
    verbage () {
      return this.id === 'new' ? 'Add' : 'Edit'
    },
    // Returns a new Product if the route `id` is 'new', or returns an existing Product.
    item () {
      const { Product } = this.$FeathersVuex.api

      if (this.id === 'new') {
        if (this.shopId) {
          return new Product({ type: 'SHOP', shop_id: this.shopId })
        } else {
          return new Product({ type: 'GLOBAL', shop_id: this.shopId })
        }
      } else {
        return Product.getFromStore(this.id)
      }
    }
  },
  watch: {
    id: {
      handler (val) {
        // Early return if the route `:id` is 'new'
        if (val === 'new') {
          return
        }
        const { Product } = this.$FeathersVuex.api
        const existingRecord = Product.getFromStore(val)

        // If the record doesn't exist, fetch it from the API server
        // The `item` getter will automatically update after the data arrives.
        if (!existingRecord) {
          Product.get(val)
        }
      },
      // We want the above handler handler to run immediately when the component is created.
      immediate: true
    }
  },
  methods: {
    handleSaveReponse (savedProduct) {
      if (this.item.shop_id) {
        this.$router.push({ name: 'shop-products', params: { shopId: this.item.shop_id } })
      } else if (this.user.isAdmin) {
        this.$router.push({ name: 'global-products' })
      } else {
        this.$router.push({ name: 'products' })
      }
    },
    cancel () {
      if (this.id === 'new') {
        if (this.shopId || this.product.shop_id) {
          this.$router.push({ name: 'shop-products', params: { shopId: this.shopId || this.product.shop_id } })
        } else if (this.user.isAdmin) {
          this.$router.push({ name: 'global-products' })
        } else {
          this.$router.push({ name: 'products' })
        }
      } else {
        if (this.item.shop_id) {
          this.$router.push({ name: 'shop-products', params: { shopId: this.item.shop_id } })
        } else if (this.user.isAdmin) {
          this.$router.push({ name: 'global-products' })
        } else {
          this.$router.push({ name: 'products' })
        }
      }
    }
  }
}
</script>
